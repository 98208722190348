import { memo, useEffect } from "react";

import { useGetUserQuery } from "@/shared/api/general.api";
import { UserEventTypes, useCentrifuge } from "@/shared/lib/hooks";
import { useToast } from "@/shared/ui";

export const Centrifuge: React.FC = memo(() => {
    const { toast } = useToast();
    const { subscribeUserChannel, subscribePublicChannel } =
        useCentrifuge("cm:user#@userId@");
    const { data: user } = useGetUserQuery();

    useEffect(() => {
        if (!user) return;

        const userChannel = subscribeUserChannel(
            user,
            UserEventTypes.NOTIFICATION,
            (data) => {
                toast({
                    description: (
                        <div
                            className="[&>a]:text-primary"
                            dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                                __html: data?.body?.text,
                            }}
                        />
                    ),
                });
            },
        );

        return () => {
            if (userChannel) userChannel.unsubscribe();
        };
    }, [subscribeUserChannel, user]);

    useEffect(() => {
        const publicChannel = subscribePublicChannel(
            "publicChannel",
            "publicEvent",
            (data) => {
                console.log("Public event data:", data);
            },
        );

        return () => {
            if (publicChannel) publicChannel.unsubscribe();
        };
    }, [subscribePublicChannel]);

    return null;
});

Centrifuge.displayName = "Centrifuge";

import { debounce } from "lodash";
import { BaseSelectRef, SelectProps } from "rc-select";
import { forwardRef, useMemo } from "react";

import { useLazyGetSuggestionQuery } from "@/shared/api/general.api";
import { WrappedSelect } from "@/shared/ui";

type Value = string | string[] | undefined;

interface SuggestionProps extends SelectProps {
    name?: string;
    value?: Value;
    clientId?: number;
}

interface Option {
    label: string;
    value: string;
}

const getInitialData = (value: Value): string[] => {
    if (!value) return [];
    if (Array.isArray(value)) return value;
    return [value];
};

export const Suggestion: React.FC<SuggestionProps> = forwardRef<
    BaseSelectRef,
    SuggestionProps
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
>((props, _ref) => {
    const [getSuggestion, { data = getInitialData(props?.value), isFetching }] =
        useLazyGetSuggestionQuery();

    const options: Option[] = useMemo(
        () => data?.map((item) => ({ label: item, value: item })) ?? [],
        [data],
    );

    const onSearch = debounce(async (value: string) => {
        await getSuggestion({
            clientId: props?.clientId,
            name: props?.name?.split(".").pop() ?? "",
            term: value,
        });
    }, 500);

    return (
        <WrappedSelect
            loading={isFetching}
            onSearch={onSearch}
            filterOption={false}
            options={options}
            {...props}
        />
    );
});

Suggestion.displayName = "Suggestion";

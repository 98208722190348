import { baseApi as api } from "./base.api";

export const addTagTypes = [
    "Auth",
    "Vehicles",
    "User",
    "Suggestion",
    "Glossary",
    "Device",
    "Geofense",
    "Events",
] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            postLogin: build.mutation<PostLoginApiResponse, PostLoginApiArg>({
                query: (queryArg) => ({
                    url: `/login`,
                    method: "POST",
                    body: queryArg,
                }),
                invalidatesTags: ["Auth"],
            }),
            postForgotPassword: build.mutation<
                PostForgotPasswordApiResponse,
                PostForgotPasswordApiArg
            >({
                query: (queryArg) => ({
                    url: `/forgot-password`,
                    method: "POST",
                    body: queryArg,
                }),
                invalidatesTags: ["Auth"],
            }),
            postResetPassword: build.mutation<
                PostResetPasswordApiResponse,
                PostResetPasswordApiArg
            >({
                query: (queryArg) => ({
                    url: `/reset-password`,
                    method: "POST",
                    body: queryArg,
                }),
                invalidatesTags: ["Auth"],
            }),
            postLogout: build.mutation<PostLogoutApiResponse, PostLogoutApiArg>(
                {
                    query: () => ({ url: `/logout`, method: "POST" }),
                    invalidatesTags: ["Auth"],
                },
            ),
            getVehicles: build.query<GetVehiclesApiResponse, GetVehiclesApiArg>(
                {
                    query: (queryArg) => ({
                        url: `/vehicles`,
                        params: {
                            page: queryArg.page,
                            filter: queryArg.filter,
                        },
                    }),
                    providesTags: ["Vehicles"],
                },
            ),
            getVehiclesById: build.query<
                GetVehiclesByIdApiResponse,
                GetVehiclesByIdApiArg
            >({
                query: (queryArg) => ({ url: `/vehicles/${queryArg}` }),
            }),
            getVehiclesByIdActivity: build.query<
                GetVehiclesByIdActivityApiResponse,
                GetVehiclesByIdActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/vehicles/${queryArg.id}/activity`,
                    params: { filter: queryArg.filter },
                }),
            }),
            getUserSettings: build.query<
                GetUserSettingsApiResponse,
                GetUserSettingsApiArg
            >({
                query: () => ({ url: `/user/settings` }),
            }),
            putUserSettings: build.mutation<
                PutUserSettingsApiResponse,
                PutUserSettingsApiArg
            >({
                query: (queryArg) => ({
                    url: `/user/settings`,
                    method: "PUT",
                    body: queryArg,
                }),
            }),
            getUserOperators: build.query<
                GetUserOperatorsApiResponse,
                GetUserOperatorsApiArg
            >({
                query: () => ({ url: `/user/operators` }),
                providesTags: ["User"],
            }),
            getUser: build.query<GetUserApiResponse, GetUserApiArg>({
                query: () => ({ url: `/user` }),
                providesTags: ["User"],
            }),
            getSuggestion: build.query<
                GetSuggestionApiResponse,
                GetSuggestionApiArg
            >({
                query: (queryArg) => ({
                    url: `/suggestion`,
                    params: {
                        name: queryArg.name,
                        term: queryArg.term,
                        client_id: queryArg.clientId,
                    },
                }),
                providesTags: ["Suggestion"],
            }),
            getGlossary: build.query<GetGlossaryApiResponse, GetGlossaryApiArg>(
                {
                    query: () => ({ url: `/glossary` }),
                    providesTags: ["Glossary"],
                },
            ),
            getDevices: build.query<GetDevicesApiResponse, GetDevicesApiArg>({
                query: (queryArg) => ({
                    url: `/devices`,
                    params: { page: queryArg.page, filter: queryArg.filter },
                }),
                providesTags: ["Device"],
            }),
            getDevicesById: build.query<
                GetDevicesByIdApiResponse,
                GetDevicesByIdApiArg
            >({
                query: (queryArg) => ({ url: `/devices/${queryArg}` }),
                providesTags: ["Device"],
            }),
            getDevicesByIdGeofence: build.query<
                GetDevicesByIdGeofenceApiResponse,
                GetDevicesByIdGeofenceApiArg
            >({
                query: (queryArg) => ({ url: `/devices/${queryArg}/geofence` }),
                providesTags: ["Geofense"],
            }),
            getDevicesByIdGps: build.query<
                GetDevicesByIdGpsApiResponse,
                GetDevicesByIdGpsApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/${queryArg.id}/gps`,
                    params: { filter: queryArg.filter },
                }),
            }),
            postDevicesByIdArchive: build.mutation<
                PostDevicesByIdArchiveApiResponse,
                PostDevicesByIdArchiveApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/${queryArg.id}/archive`,
                    method: "POST",
                    body: queryArg.body,
                }),
            }),
            getDevicesByIdOnline: build.query<
                GetDevicesByIdOnlineApiResponse,
                GetDevicesByIdOnlineApiArg
            >({
                query: (queryArg) => ({
                    url: `/devices/${queryArg.id}/online`,
                    params: { filter: queryArg.filter },
                }),
            }),
            getDeviceTimeControl: build.query<
                GetDeviceTimeControlApiResponse,
                GetDeviceTimeControlApiArg
            >({
                query: (queryArg) => ({
                    url: `/device-time-control`,
                    params: { page: queryArg.page, filter: queryArg.filter },
                }),
            }),
            getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
                query: (queryArg) => ({
                    url: `/events`,
                    params: { page: queryArg.page, filter: queryArg.filter },
                }),
                providesTags: ["Events"],
            }),
            getEventsById: build.query<
                GetEventsByIdApiResponse,
                GetEventsByIdApiArg
            >({
                query: (queryArg) => ({ url: `/events/${queryArg}` }),
            }),
            getDrivers: build.query<GetDriversApiResponse, GetDriversApiArg>({
                query: (queryArg) => ({
                    url: `/drivers`,
                    params: { page: queryArg.page, filter: queryArg.filter },
                }),
            }),
            getDriversById: build.query<
                GetDriversByIdApiResponse,
                GetDriversByIdApiArg
            >({
                query: (queryArg) => ({ url: `/drivers/${queryArg}` }),
            }),
            getIndividualAnalyticalReport: build.query<
                GetIndividualAnalyticalReportApiResponse,
                GetIndividualAnalyticalReportApiArg
            >({
                query: (queryArg) => ({
                    url: `/individual-analytical-report`,
                    params: { filter: queryArg },
                }),
            }),
            getDriverStatisticReport: build.query<
                GetDriverStatisticReportApiResponse,
                GetDriverStatisticReportApiArg
            >({
                query: (queryArg) => ({
                    url: `/driver-statistic-report`,
                    params: { page: queryArg.page, filter: queryArg.filter },
                }),
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as generalApi };
export type PostLoginApiResponse = /** status 200  */ AuthResponse;
export type PostLoginApiArg = AuthRequest;
export type PostForgotPasswordApiResponse =
    /** status 200  */ SuccessMessageResponse;
export type PostForgotPasswordApiArg = ForgotPasswordRequest;
export type PostResetPasswordApiResponse = /** status 200  */ SuccessResponse;
export type PostResetPasswordApiArg = ResetPasswordRequest;
export type PostLogoutApiResponse = /** status 200  */ SuccessResponse;
export type PostLogoutApiArg = void;
export type GetVehiclesApiResponse = /** status 200  */ {
    data: VehicleResponse[];
    /** Всего */
    total: number;
    /** Всего элементов на странице */
    per_page: number;
    /** Номер страницы */
    current_page: number;
};
export type GetVehiclesApiArg = {
    /** Номер страницы */
    page?: number;
    filter?: {
        client_id?: number;
        org_struct_lvl1_id?: number;
        org_struct_lvl2_id?: number;
        side_number?: string[];
        registration_number?: string;
        vin?: string;
        type?: string;
        trashed?: "with" | "only";
        brand?: string;
        model?: string;
    };
};
export type GetVehiclesByIdApiResponse = /** status 200  */ VehicleResponse;
export type GetVehiclesByIdApiArg = number;
export type GetVehiclesByIdActivityApiResponse = /** status 200  */ {
    /** Дата */
    name: string;
    /** Время онлайн в минутах */
    y: number;
}[];
export type GetVehiclesByIdActivityApiArg = {
    id: number;
    filter?: {
        date_from?: string;
        date_to?: string;
    };
};
export type GetUserSettingsApiResponse = /** status 200  */ UserSettingsRes;
export type GetUserSettingsApiArg = void;
export type PutUserSettingsApiResponse = /** status 200  */ SuccessResponse;
export type PutUserSettingsApiArg = {
    name?: string;
    password?: string;
    timezone?: string;
    sip_number?: string;
    sip_extension?: string;
};
export type GetUserOperatorsApiResponse = /** status 200  */ {
    id: number;
    name: string;
}[];
export type GetUserOperatorsApiArg = void;
export type GetUserApiResponse = /** status 200  */ UserResponse;
export type GetUserApiArg = void;
export type GetSuggestionApiResponse = /** status 200  */ string[];
export type GetSuggestionApiArg = {
    /** Название поля для поиска */
    name: string;
    /** строка для поиска */
    term: string;
    /** ID Клиента */
    clientId?: number;
};
export type GetGlossaryApiResponse = /** status 200  */ {
    device_status: {
        id?: number;
        name?: string;
    }[];
    device_type: {
        id?: number;
        name?: string;
    }[];
    event_group: {
        name: string;
        types: {
            id: number;
            slug: string;
            name: string;
        }[];
    }[];
    verification_status: {
        id: number;
        name: string;
        slug: string;
    }[];
    vehicle_types: {
        id: number;
        name: string;
        slug: string;
    }[];
};
export type GetGlossaryApiArg = void;
export type GetDevicesApiResponse = /** status 200  */ {
    current_page: number;
    per_page: number;
    total: number;
    data: DeviceResponse[];
};
export type GetDevicesApiArg = {
    /** Номер страницы */
    page?: number;
    filter?: {
        client_id?: number;
        org_struct_lvl1_id?: number;
        org_struct_lvl2_id?: number;
        serial_number?: string;
        side_number?: string[];
        name?: string[];
        device_type?: number;
        status?: number;
        version?: string;
        online?: number;
        offline?: number;
        defective?: number;
        working?: number;
    };
};
export type GetDevicesByIdApiResponse = /** status 200  */ DeviceResponse;
export type GetDevicesByIdApiArg = string;
export type GetDevicesByIdGeofenceApiResponse =
    /** status 200  */ GeofenceResponse;
export type GetDevicesByIdGeofenceApiArg = string;
export type GetDevicesByIdGpsApiResponse = /** status 200  */ {
    track: {
        lat: number;
        lng: number;
        timeIso: number;
        speed: number;
    }[];
    events: {
        id: number;
        dtime: string;
        speed: number;
        latitude: string;
        longitude: string;
        type: number;
        type_name: string;
    }[];
};
export type GetDevicesByIdGpsApiArg = {
    id: string;
    filter?: {
        date_from?: string;
        date_to?: string;
        driver_uid?: string;
        type?: string[];
    };
};
export type PostDevicesByIdArchiveApiResponse =
    /** status 200  */ SuccessMessageResponse;
export type PostDevicesByIdArchiveApiArg = {
    id: string;
    body: {
        date_from: string;
        date_to: string;
        channel: string[];
    };
};
export type GetDevicesByIdOnlineApiResponse = /** status 200  */ number[][];
export type GetDevicesByIdOnlineApiArg = {
    id: number;
    filter?: {
        date?: string | null;
    };
};
export type GetDeviceTimeControlApiResponse = /** status 200  */ {
    current_page: number;
    per_page: number;
    total: number;
    data: DeviceControlTimeRes[];
};
export type GetDeviceTimeControlApiArg = {
    /** Номер страницы */
    page?: number;
    filter?: {
        client_id?: number;
        org_struct_lvl1_id?: number;
        org_struct_lvl2_id?: number;
        date_from?: string;
        date_to?: string;
        side_number?: number;
        vin?: string;
        special?: number;
    };
};
export type GetEventsApiResponse = /** status 200  */ {
    current_page: number;
    per_page: number;
    total: number;
    data: EventResponse[];
};
export type GetEventsApiArg = {
    /** Номер страницы */
    page?: number;
    filter?: {
        client_id?: number;
        org_struct_lvl1_id?: number;
        org_struct_lvl2_id?: number;
        geofence?: string[];
        side_number?: number;
        device_name?: string[];
        device_type?: number;
        date_from?: string;
        date_to?: string;
        type?: string[];
        device_id?: number;
        driver_uid?: string;
        external_driver_id?: string;
        operator_id?: number;
        verification_status?: number;
        speed_not_null?: 0 | 1;
    };
};
export type GetEventsByIdApiResponse = /** status 200  */ {
    id: number;
    type: string;
    created_at: string;
    received_at: string;
    status: 0 | 1 | 2 | 3;
    speed?: number;
    latitude?: string;
    longitude?: string;
    photo?: string | null;
    device: {
        id: number;
        name: string | null;
    };
    vehicle?: {
        id: number | null;
        registration_number: string;
        side_number: string;
    };
    driver?: {
        id: number | null;
        name: string | null;
        personal_number: string | null;
    };
    client: {
        id?: number;
        name?: string;
    };
    org_struct_lvl1: {
        /** ID */
        id: number | null;
        /** name */
        name: string | null;
    };
    org_struct_lvl2: {
        /** ID */
        id: number | null;
        name: string | null;
    };
    video: {
        channel: number;
        url: string;
    }[];
};
export type GetEventsByIdApiArg = number;
export type GetDriversApiResponse = /** status 200  */ {
    current_page: number;
    per_page: number;
    total: number;
    data: DriversRes[];
};
export type GetDriversApiArg = {
    /** Номер страницы */
    page?: number;
    filter?: {
        client_id?: number;
        org_struct_lvl1_id?: number;
        org_struct_lvl2_id?: number;
        driver_uid?: string;
        external_driver_id?: string;
        has_external_id?: number | null;
    };
};
export type GetDriversByIdApiResponse = /** status 200  */ DriversRes;
export type GetDriversByIdApiArg = number;
export type GetIndividualAnalyticalReportApiResponse =
    /** status 200  */ SuccessResponse;
export type GetIndividualAnalyticalReportApiArg = {
    client_id?: number;
    org_struct_lvl1_id?: number;
    org_struct_lvl2_id?: number;
    date_from?: string;
    date_to?: string;
    type?: string[];
};
export type GetDriverStatisticReportApiResponse = /** status 200  */ {
    current_page: number;
    per_page: number;
    total: number;
    data: DriverStatisticReportRes[];
};
export type GetDriverStatisticReportApiArg = {
    page?: number;
    filter?: {
        client_id?: number;
        org_struct_lvl1_id?: number;
        org_struct_lvl2_id?: number;
        date_from?: string;
        date_to?: string;
        external_driver_id?: string;
    };
};
export type AuthResponse = {
    token?: string;
};
export type ErrorResponse = {
    errors?: {
        field_name?: string[];
    };
    message: string;
};
export type ValidationErrorResponse = {
    errors?: {
        field_name?: string[];
    };
    message: string;
};
export type AuthRequest = {
    /** Адрес электронной почты пользователя */
    email: string;
    /** Пароль пользователя */
    password: string;
};
export type SuccessMessageResponse = {
    message: string;
    title?: string;
};
export type ForgotPasswordRequest = {
    /** Адрес электронной почты пользователя */
    email: string;
};
export type SuccessResponse = {
    success?: boolean;
};
export type ResetPasswordRequest = {
    /** Адрес электронной почты пользователя */
    email: string;
    /** Пароль пользователя */
    password: string;
    /** Повторный пароль */
    password_confirmation: string;
    /** Токен для восстановления пароля */
    token: string;
};
export type VehicleResponse = {
    /** ID устройства */
    id: number;
    /** VIN */
    vin: string;
    /** Тип ТС */
    type?: {
        /** Id типа ТС */
        id: number | null;
        /** Название типа ТС */
        name: string | null;
    };
    /** Марка ТС */
    brand?: string;
    /** Модель ТС */
    model: string;
    registration_number: string;
    /** Бортовой номер */
    side_number: string;
    /** Имя устройства */
    device: {
        id: number | null;
        name: string | null;
    };
    /** Клиент */
    client: {
        /** ID клиента */
        id: number;
        /** Название клиента */
        name: string;
    };
    /** Орг структура 1 ур */
    org_struct_lvl1: {
        /** ID Орг структуры */
        id: number | null;
        /** Название орг структуры */
        name: string | null;
    };
    /** Орг структура 2 ур */
    org_struct_lvl2?: {
        /** ID Орг структуры */
        id: number | null;
        /** Название орг структуры */
        name: string | null;
    };
};
export type UserSettingsRes = {
    name: string;
    timezone: string;
    sip_number?: string;
    sip_extension?: string;
};
export type UserResponse = {
    id: number;
    name: string;
    email: string;
    role?: {
        id?: number;
        name?: string;
    };
    permissions?: string[];
    time_zone: string;
    locale: string;
    clients?: {
        id?: number;
        name?: string;
    }[];
    org_structs?: {
        id?: number;
        name?: string;
        client_id?: number;
        level2?: {
            id?: number;
            name?: string;
        }[];
    }[];
};
export type DeviceResponse = {
    /** ID */
    id: number;
    side_number: string | null;
    /** name */
    name: string;
    is_online: boolean;
    last_ping: string | null;
    vehicle: {
        id: number | null;
        registration_number: string | null;
    };
    iccid: string;
    version: string | null;
    driver_name: string | null;
    speed: number | null;
    latitude: string | null;
    longitude: string | null;
    client: {
        id: number | null;
        name: string | null;
    };
    org_struct_lvl1: {
        /** ID */
        id: number | null;
        /** name */
        name: string | null;
    };
    org_struct_lvl2: {
        /** ID */
        id: number | null;
        /** name */
        name: string | null;
    };
    status: {
        id: number;
        name: string;
    };
};
export type GeofenceResponse = {
    type: string;
    features: {
        type: string;
        properties: {
            id: number;
            name: string;
            hasSettings: boolean;
            externalIntegrationName: string | null;
        };
        geometry: {
            type: string;
            coordinates: number[][][];
        };
    }[];
};
export type DeviceControlTimeRes = {
    /** ID устройства */
    id: number;
    /** VIN */
    vin: string;
    /** Бортовой номер */
    side_number: string;
    /** Имя устройства */
    device: {
        id: number | null;
        name: string | null;
    };
    /** Клиент */
    client: {
        /** ID клиента */
        id: number;
        /** Название клиента */
        name: string;
    };
    /** Орг структура 1 ур */
    org_struct_lvl1: {
        /** ID Орг структуры */
        id: number | null;
        /** Название орг структуры */
        name: string | null;
    };
    /** Орг структура 2 ур */
    org_struct_lvl2?: {
        /** ID Орг структуры */
        id: number | null;
        /** Название орг структуры */
        name: string | null;
    };
    list: {
        date: string;
        time: string;
        odometer: number;
        average_speed: number;
    }[];
};
export type EventResponse = {
    id: number;
    type: string;
    created_at: string;
    received_at: string;
    status: 0 | 1 | 2 | 3;
    speed?: number;
    latitude?: string;
    longitude?: string;
    photo?: string | null;
    device: {
        id: number;
        name: string | null;
    };
    vehicle?: {
        id: number | null;
        registration_number: string;
        side_number: string;
    };
    driver?: {
        id: number | null;
        name: string | null;
        personal_number: string | null;
    };
    client: {
        id?: number;
        name?: string;
    };
    org_struct_lvl1: {
        /** ID */
        id: number | null;
        /** name */
        name: string | null;
    };
    org_struct_lvl2: {
        /** ID */
        id: number | null;
        name: string | null;
    };
    video: {
        channel: number | null;
        url: string | null;
    }[];
};
export type DriversRes = {
    id: number;
    uid: string;
    external_id?: string;
    latitude?: string;
    longitude?: string;
    photo?: string;
    client: {
        id: number;
        name: string;
    };
    org_struct_lvl1: {
        /** ID */
        id: number;
        name: string;
    };
    org_struct_lvl2?: {
        /** ID */
        id: number;
        name: string;
    };
};
export type DriverStatisticReportRes = {
    external_driver_id: string;
    events: {
        falling_asleep?: number;
        looking_aside?: number;
        smoking?: number;
    };
    rating: number;
    working_hours: number;
    active_hours: number[];
};
export const {
    usePostLoginMutation,
    usePostForgotPasswordMutation,
    usePostResetPasswordMutation,
    usePostLogoutMutation,
    useGetVehiclesQuery,
    useLazyGetVehiclesQuery,
    useGetVehiclesByIdQuery,
    useLazyGetVehiclesByIdQuery,
    useGetVehiclesByIdActivityQuery,
    useLazyGetVehiclesByIdActivityQuery,
    useGetUserSettingsQuery,
    useLazyGetUserSettingsQuery,
    usePutUserSettingsMutation,
    useGetUserOperatorsQuery,
    useLazyGetUserOperatorsQuery,
    useGetUserQuery,
    useLazyGetUserQuery,
    useGetSuggestionQuery,
    useLazyGetSuggestionQuery,
    useGetGlossaryQuery,
    useLazyGetGlossaryQuery,
    useGetDevicesQuery,
    useLazyGetDevicesQuery,
    useGetDevicesByIdQuery,
    useLazyGetDevicesByIdQuery,
    useGetDevicesByIdGeofenceQuery,
    useLazyGetDevicesByIdGeofenceQuery,
    useGetDevicesByIdGpsQuery,
    useLazyGetDevicesByIdGpsQuery,
    usePostDevicesByIdArchiveMutation,
    useGetDevicesByIdOnlineQuery,
    useLazyGetDevicesByIdOnlineQuery,
    useGetDeviceTimeControlQuery,
    useLazyGetDeviceTimeControlQuery,
    useGetEventsQuery,
    useLazyGetEventsQuery,
    useGetEventsByIdQuery,
    useLazyGetEventsByIdQuery,
    useGetDriversQuery,
    useLazyGetDriversQuery,
    useGetDriversByIdQuery,
    useLazyGetDriversByIdQuery,
    useGetIndividualAnalyticalReportQuery,
    useLazyGetIndividualAnalyticalReportQuery,
    useGetDriverStatisticReportQuery,
    useLazyGetDriverStatisticReportQuery,
} = injectedRtkApi;

// hook for filters
import qs from "qs";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const processObject = (data: Record<string, unknown>) => {
    const result: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(data)) {
        if (!value) continue;

        if (typeof value === "object" && !Array.isArray(value)) {
            result[key] = processObject(value as Record<string, unknown>); // Recursive call for nested objects
            continue;
        }

        if (!Number.isNaN(+value)) {
            result[key] = +value;
            continue;
        }

        result[key] = value;
    }

    return result;
};

const STRINGIFY_OPTIONS: qs.IStringifyOptions = {
    arrayFormat: "brackets",
    encodeValuesOnly: true,
    skipNulls: true,
    format: "RFC1738",
};

const PARSE_OPTIONS: qs.IParseOptions = {};

export const useFilter = <T extends Record<string, unknown>>(
    defaultValue?: T,
) => {
    const [searchParams, setSearchParams] = useSearchParams(
        qs.stringify(defaultValue, STRINGIFY_OPTIONS),
    );

    const filter = useMemo(() => {
        const queryDataFromUrl = qs.parse(
            searchParams.toString(),
            PARSE_OPTIONS,
        );

        return processObject(queryDataFromUrl) as T;
    }, [searchParams]);

    const setFilter = useCallback(
        (newFilter?: T) => {
            setSearchParams(qs.stringify(newFilter, STRINGIFY_OPTIONS), {
                replace: true,
            });
        },
        [setSearchParams],
    );

    useEffect(() => {
        setSearchParams((prev) => prev, { replace: true });
    }, [setSearchParams]);

    return { filter, setFilter };
};

import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
    RouterProvider,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

import { Centrifuge, Loading, TranslationCheck } from "@/features";

import "@/shared/lib/i18n";
import { Toaster } from "@/shared/ui";

import "./global.css";
import { router } from "./router";
import { store } from "./store";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    // Tracing
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_RATE, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [
    //     "localhost:8080",
    //     /^https:\/\/yourserver\.io\/api/,
    // ],
    // Session Replay
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ERROR_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Suspense fallback={<Loading />}>
                <Provider store={store}>
                    <RouterProvider router={router} />
                    <Toaster />
                    <TranslationCheck />
                    <Centrifuge />
                </Provider>
            </Suspense>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
);
